import { createSlice } from "@reduxjs/toolkit";

export const phoneSlice = createSlice({
  name: "phone",
  initialState: "",
  reducers: {
    setPhone: (state, action) => {
      return action.payload;
    },
  },
});

export const { setPhone } = phoneSlice.actions;
export default phoneSlice.reducer;
