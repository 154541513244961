import { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import LoginLayout from "../../layouts/LoginLayout";
import { resetPassword } from "../../service/omvApi";
import CustomForm from "../../components/CustomForm";
import { safe } from "../../util";
import { setApplicationBusy } from "../../state/applicationControlSlice";

export default function ResetPassword() {
  const { texts } = useSelector((state) => state.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [busy, setBusy] = useState(false);
  let { accessCode } = useParams();

  useEffect(() => {
    if (!accessCode?.length) {
      navigate("/login-as-registered");
    }
  }, []);

  const onResetPassword = () =>
    safe(async () => {
      setBusy(true);
      dispatch(setApplicationBusy(true));
      const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
      if (newPassword !== confirmPassword) {
        toast.error(texts.password.dont_match);
        return;
      }
      if (!passwordRegex.test(newPassword)) {
        toast.error(texts.password.weak_password);
        return;
      }
      await resetPassword(accessCode, newPassword);
      toast.success(texts.password_success_change);
      setTimeout(() => navigate("/login-as-registered"), 3000);
    }).finally(() => {
      setBusy(false);
      dispatch(setApplicationBusy(false));
    });

  return (
    <LoginLayout>
      <CustomForm onSubmit={onResetPassword}>
        <Stack spacing={4}>
          <Typography variant="title" component="h4" align="left">
            {texts.password.set_pass}
          </Typography>
          <Typography component="h4" align="left" sx={{ mb: 6 }}>
            {texts.password.set_pass_desc}
          </Typography>
          <TextField
            fullWidth
            disabled={busy}
            type={showNewPassword ? "text" : "password"}
            label={texts.common.new_pass}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    tabIndex={-1}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              maxLength: 30,
            }}
          />
          <TextField
            fullWidth
            disabled={busy}
            type={showConfirmPassword ? "text" : "password"}
            label={texts.common.confirm_pass}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    tabIndex={-1}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            loading={busy}
            variant="submit"
            sx={{ mt: 2 }}
            type="submit"
          >
            {texts.common.change_pass}
          </LoadingButton>
        </Stack>
      </CustomForm>
    </LoginLayout>
  );
}
