import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Drawer,
  Toolbar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  AppBar as MuiAppBar,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer, closeDrawer } from "../state/drawerSlice";
import ChooseLanguage from "../components/ChooseLanguage";
import { hasAdminRole } from "../util";
import { useAuthentication } from "../hooks/useAuthentication";
import { LOGIN_AS_REGISTERED_PATH } from "../constants/pathConstants";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: window.innerWidth <= 600 ? "6px" : theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? 0 : window.innerWidth <= 600 ? "0" : `-255px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - $40px)`,
    marginLeft: `255px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function AppLayout(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isOpenDrawer = useSelector((state) => state.drawer);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { texts } = useSelector((state) => state.language);
  const userInfo = useSelector((state) => state.userInfo);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const { handleLogout } = useAuthentication();

  const isAdmin = hasAdminRole();

  const [drawerVariant, setDrawerVariant] = useState(
    isMobile ? "temporary" : "persistent",
  );

  const menuItems = useMemo(
    () => [
      {
        path: "/order-list",
        text: texts.sidemenu.customer.order_list,
        icon: "/order-list-icon.svg",
        visible: true,
      },
      {
        path: "/ev-list",
        text: texts.sidemenu.admin.ev_list,
        icon: "/ev-list-icon.svg",
        visible: true,
      },
      {
        path: "/customer-data",
        text: texts.sidemenu.customer.customer_data,
        icon: "/customer-data-icon.svg",
        visible: !isAdmin,
      },
    ],
    [isAdmin, texts],
  );

  const handleDrawerOpen = useCallback(() => {
    dispatch(openDrawer());
  }, []);

  const handleDrawerClose = useCallback(() => {
    dispatch(closeDrawer());
  }, []);

  window.addEventListener("resize", () => {
    setDrawerVariant(window.innerWidth > 600 ? "persistent" : "temporary");
  });

  const onActiveAccountButtonClicked = useCallback(() => {
    navigate("/activate-account");
  }, []);

  const onMenuItemClick = useCallback(() => {
    if (isMobile) {
      handleDrawerClose();
    }
  }, [handleDrawerClose]);

  const onLogoutClick = () => {
    handleLogout();
    navigate(LOGIN_AS_REGISTERED_PATH);
    setUserAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{ color: isAdmin ? "#0E9C63" : "rgb(0, 47, 108)" }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          {userInfo?.registered === false && (
            <Button
              variant="submit"
              onClick={onActiveAccountButtonClicked}
              sx={{
                marginRight: 2,
                fontSize: isMobile ? "0.625rem" : undefined,
              }}
              size="small"
            >
              {texts.common.activate_acc}
            </Button>
          )}
          <ChooseLanguage />
          <IconButton
            color="inherit"
            onClick={(e) => setUserAnchorEl(e.currentTarget)}
            sx={{ ml: "15px" }}
            disableRipple
          >
            <img src="/user.png" alt="user" style={{ height: 38 }} />
            {userInfo && userInfo.registered ? (
              <Box ml={1}>
                <Typography variant="14" component="div" align="left">
                  {userInfo.firstName} {isMobile ? <br /> : ""}
                  {userInfo.lastName}
                </Typography>
                <Typography variant="12" component="div" align="left">
                  {isAdmin ? texts.role.admin : texts.role.customer}
                </Typography>
              </Box>
            ) : (
              <Box ml={1}>
                <Typography variant="14" component="div" align="left">
                  {texts.common.unregistered}
                </Typography>
                <Typography variant="12" component="div" align="left">
                  {texts.role.customer}
                </Typography>
              </Box>
            )}
          </IconButton>
          <Menu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => setUserAnchorEl(null)}
          >
            <MenuItem onClick={onLogoutClick}>{texts.common.logout}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: 255,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 255,
            boxSizing: "border-box",
            backgroundColor: isAdmin ? "#0E9C63" : "#002F6C",
          },
        }}
        variant={drawerVariant}
        anchor="left"
        open={isOpenDrawer}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon sx={{ color: "white" }} />
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem style={{ display: "flex", justifyContent: "center" }}>
            <ListItemIcon sx={{ paddingBottom: "42px" }}>
              <img
                src="/logo-sidebar.svg"
                alt="ovv logo"
                style={{
                  width: 90,
                  height: 66,
                }}
              />
            </ListItemIcon>
          </ListItem>

          {menuItems
            .filter((item) => item.visible)
            .map((item, index) => (
              <ListItem
                key={index}
                component={RouterLink}
                to={item.path}
                className={
                  location.pathname === item.path ? "selectedListItem" : ""
                }
                sx={{
                  background:
                    location.pathname === item.path
                      ? "linear-gradient(90deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0) 98.8%)"
                      : "transparent",
                }}
                onClick={onMenuItemClick}
              >
                <ListItemIcon sx={{ minWidth: 0, marginRight: "10px" }}>
                  <img src={item.icon} alt={item.text} />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: "white",
                    "& .MuiTypography-root": {
                      fontWeight: "700",
                    },
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Drawer>
      <Main
        open={isOpenDrawer}
        style={{
          backgroundColor: "#F9F9F9",
          marginTop: "35px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  );
}
