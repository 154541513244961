import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "react-toastify";
import {
  DownloadVouchersButton,
  ExportVouchers,
  ViewVoucherButton,
  VoucherStatus,
} from "./components";
import CustomDataGrid from "../../components/list-view/CustomDataGrid";
import CustomModal from "../../components/CustomModal";
import { getEvList, viewVoucher } from "../../service/omvApi";
import { safe } from "../../util";
import CustomForm from "../../components/CustomForm";
import DateTableCell from "../../components/tableCell/DateTableCell";
import { useDataGrid } from "../../hooks/useDataGrid";
import {
  selectSelectedEVoucherRows,
  setSelectedRows,
} from "../../state/evoucherSlice";

export default function EVoucherList(props) {
  const { orderGuid, dataGridConfig } = props;
  const dispatch = useDispatch();

  const { texts, dateFormats, language } = useSelector(
    (state) => state.language,
  );
  const [tableData, setTableData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [imgContent, setImgContent] = useState(null);
  const [modalBusy, setModalBusy] = useState(false);
  const [modalError, setModalError] = useState(false);

  /**
   * Filter
   */
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("ANY");
  const [lastChangedStartDate, setLastChangedStartDate] = useState(null);
  const [lastChangedEndDate, setLastChangedEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const filterBreakPoint = useMemo(() => {
    return orderGuid ? { xs: 12, sm: 6 } : { xs: 12, sm: 6, md: 2.5, xl: 2 };
  }, [orderGuid]);

  const onFetchEVouchers = (pagination, sortModel) => {
    safe(async () => {
      setLoading(true);
      const sortItem = sortModel?.length ? sortModel[0] : undefined;
      const params = {
        page: pagination.page ? pagination.page + 1 : 1,
        rows: pagination.pageSize || 10,
        sidx: sortItem?.field,
        sord: sortItem?.sort ? sortItem.sort.toUpperCase() : undefined,
        searchString: query,
        voucherStatus: status === "ANY" ? null : status,
        lastChangedStartDate: lastChangedStartDate,
        lastChangedEndDate: lastChangedEndDate,
      };
      if (orderGuid) {
        params.orderGuid = orderGuid;
      }
      const res = await getEvList(params);
      setRowCount(res.records || 0);
      const items = res.rows.map((item) => {
        item.id = item.serialNo;
        return item;
      });
      setTableData(items);
    }).finally(() => {
      setLoading(false);
    });
  };

  const {
    paginationModel,
    rowCount,
    setRowCount,
    sortModel,
    onPaginationModelChange,
    onFilterQuery,
    onSortModelChange,
  } = useDataGrid({
    queryCallback: onFetchEVouchers,
  });

  const selectedRows = useSelector(selectSelectedEVoucherRows);

  useEffect(() => {
    onFetchEVouchers(paginationModel);
    return () => {
      dispatch(setSelectedRows([]));
    };
  }, []);

  const handleRowSelection = (newSelection) => {
    dispatch(setSelectedRows(newSelection));
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setModalError(false);
  };

  const columns = useMemo(
    () => [
      {
        field: "voucherStatusName",
        headerName: texts.header.ev.status,
        minWidth: 100,
        flex: 1,
        cellType: "text",
        renderCell: (params) => <VoucherStatus params={params} />,
      },
      {
        field: "lastChanged",
        headerName: texts.header.ev.status_date,
        minWidth: 125,
        flex: 1,
        cellType: "date",
        renderCell: (params) => (
          <DateTableCell {...params} fieldName={"lastChanged"} />
        ),
      },
      {
        field: "orderDate",
        headerName: texts.header.ev.date,
        minWidth: 125,
        flex: 1,
        cellType: "date",
        renderCell: (params) => (
          <DateTableCell {...params} fieldName={"orderDate"} />
        ),
      },
      {
        field: "orderNo",
        headerName: texts.header.ev.nr,
        minWidth: 125,
        flex: 2,
        cellType: "text",
      },
      {
        field: "voucherTypeDescription",
        headerName: texts.header.ev.tip_be,
        minWidth: 225,
        flex: 2,
        cellType: "text",
      },
      {
        field: "amount",
        headerName: texts.header.ev.valoa_be,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "externalSerialNo",
        headerName: texts.header.ev.seie_exter,
        minWidth: 150,
        flex: 1,
        cellType: "text",
      },
      {
        field: "view",
        headerName: texts.common.view,
        minWidth: 125,
        flex: 1,
        cellType: "action",
        sortable: false,
        renderCell: (params) => (
          <ViewVoucherButton
            params={params}
            onViewVoucher={onViewVoucher}
            label={texts.common.view}
          />
        ),
      },
      {
        field: "download",
        headerName: texts.common.descarca,
        minWidth: 125,
        flex: 1.25,
        cellType: "action",
        sortable: false,
        renderCell: (params) => (
          <DownloadVouchersButton
            params={params}
            label={texts.common.descarca}
          />
        ),
      },
    ],
    [language],
  );

  const onViewVoucher = (item) =>
    safe(async () => {
      setIsOpenModal(true);
      setModalBusy(true);
      setImgContent("");
      viewVoucher(item.serialNo)
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64String = reader.result;
            setImgContent(base64String);
          };
        })
        .catch((error) => {
          toast.error(
            error?.errorMessage || error || texts.error.downloadVoucherFailed,
          );
          setModalError(true);
        })
        .finally(() => {
          setModalBusy(false);
        });
    });

  const eVoucherLocaleText = useMemo(() => {
    return {
      footerRowSelected: (count) => <ExportVouchers count={count} />,
    };
  }, []);

  return (
    <>
      <Typography variant="title">{texts.common.ev_list}</Typography>
      <CustomForm onSubmit={onFilterQuery}>
        <Grid container spacing={2} sx={{ alignItems: "center" }} wrap={"wrap"}>
          <Grid item {...filterBreakPoint}>
            <TextField
              label={texts.orderView.ev_search_by}
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              style={{ width: "100%" }}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid item {...filterBreakPoint}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="select-label" variant="outlined">
                {texts.header.ev.status}
              </InputLabel>
              <Select
                labelId="select-label"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label={texts.header.ev.status}
                variant="outlined"
              >
                <MenuItem value="ANY">{texts.header.ev.any}</MenuItem>
                <MenuItem value="AC">{texts.header.ev.active}</MenuItem>
                <MenuItem value="SU">{texts.header.ev.suspended}</MenuItem>
                <MenuItem value="BL">{texts.header.ev.blocked}</MenuItem>
                <MenuItem value="RF">{texts.header.ev.refused}</MenuItem>
                <MenuItem value="CA">{texts.header.ev.cashed}</MenuItem>
                <MenuItem value="CO">{texts.header.ev.completed}</MenuItem>
                <MenuItem value="UD">{texts.header.ev.undefined}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item {...filterBreakPoint}>
            <DatePicker
              label={texts.common.last_changed_start_date}
              value={lastChangedStartDate}
              style={{ width: "100%" }}
              sx={{ width: "100%" }}
              onChange={(date) => setLastChangedStartDate(date)}
              format={dateFormats.dateFormat}
            />
          </Grid>
          <Grid item {...filterBreakPoint}>
            <DatePicker
              label={texts.common.last_changed_end_date}
              value={lastChangedEndDate}
              sx={{ width: "100%" }}
              style={{ width: "100%" }}
              onChange={(date) => setLastChangedEndDate(date)}
              format={dateFormats.dateFormat}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} xl={1}>
            <Button type="submit" variant="submit">
              {texts.common.search}
            </Button>
          </Grid>
        </Grid>
      </CustomForm>

      <CustomDataGrid
        columns={columns}
        rows={tableData}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleRowSelection}
        checkboxSelection
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        paginationMode={"server"}
        rowCount={rowCount}
        keepNonExistentRowsSelected
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        showPageSizeOptions={dataGridConfig?.showPageSizeOptions}
        disableRowSelectionOnClick
        localeText={eVoucherLocaleText}
      />
      <CustomModal isOpen={isOpenModal} handleClose={handleClose}>
        <Box display="flex" justifyContent="center">
          {modalBusy ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <VoucherModalContent
              modalError={modalError}
              imgContent={imgContent}
            />
          )}
        </Box>
      </CustomModal>
    </>
  );
}

const getAccessLanguage = (lang) => {
  //Mapping between language codes in app and values used in http header Accept-Language
  switch (lang) {
    case "EN":
      return "en-US";
    case "RO":
      return "ro-RO";
    case "BG":
      return "bg-BG";
    default:
      return "ro-RO";
  }
};

const VoucherModalContent = (props) => {
  const { modalError, imgContent } = props;
  const { texts } = useSelector((state) => state.language);

  if (modalError) {
    return <p>{texts.error.downloadVoucherFailed}</p>;
  }
  return (
    <img
      src={imgContent}
      alt={texts.order.eVoucher}
      style={{ width: "600px", height: "auto" }}
    />
  );
};
