import React, { memo, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { downloadAndSaveFile, safe } from "../../../util";
import { downloadVoucher } from "../../../service/omvApi";

export const DownloadVouchersButton = memo(function DownloadVouchers(props) {
  const { params, label } = props;
  const { texts } = useSelector((state) => state.language);
  const [busy, setBusy] = useState(false);

  const onDownloadVouchers = (item) =>
    safe(async () => {
      setBusy(true);
      const downloadInfo = {
        guid: item?.orderGuid,
        format: "PDF",
        serialNos: [item.serialNo],
      };
      const blob = await downloadVoucher(downloadInfo);
      downloadAndSaveFile(blob, "orders_" + downloadInfo?.guid, "PDF");
    }).finally(() => {
      setBusy(false);
    });

  return (
    <LoadingButton
      variant="action"
      startIcon={<DownloadIcon />}
      onClick={(event) => {
        event.stopPropagation();
        onDownloadVouchers(params.row);
      }}
      loading={busy}
      loadingIndicator={texts.common.downloading}
    >
      {label}
    </LoadingButton>
  );
});
