import { memo } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { safeFormat } from "../../util";

const DateTableCell = memo(function DateTableCell(props) {
  const { dateFormats } = useSelector((state) => state.language);
  const dateValue = get(props.row, props.fieldName, undefined);
  return <span>{safeFormat(dateValue, dateFormats.dateFormat)}</span>;
});

export default DateTableCell;
