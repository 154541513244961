import {
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CustomForm from "../../components/CustomForm";
import LoginLayout from "../../layouts/LoginLayout";
import { forgotPassword } from "../../service/omvApi";
import { safe } from "../../util";

export default function ForgotPassword() {
  const { texts } = useSelector((state) => state.language);

  const [email, setEmail] = useState("");
  const [requestSent, setRequestSent] = useState(false);
  const [busy, setBusy] = useState(false);

  const submitPasswordRecovery = () =>
    safe(async () => {
      setBusy(true);
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        toast.error(texts.password.invalid_email);
        return;
      }

      await forgotPassword(email);
      setRequestSent(true);
    }).finally(() => {
      setBusy(false);
    });

  return (
    <LoginLayout>
      {!requestSent ? (
        <CustomForm onSubmit={submitPasswordRecovery}>
          <Stack spacing={4}>
            <Typography variant="title" component="h4" align="left">
              {texts.password.forgot_password}
            </Typography>
            <Typography component="h4" align="left" sx={{ mb: 6 }}>
              {texts.password.reg_email}
            </Typography>
            <TextField
              fullWidth
              label={texts.common.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={busy}
              InputProps={{
                endAdornment: email && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setEmail("")}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Link
              component={RouterLink}
              to="/login-as-registered"
              variant="body2"
              align="right"
              sx={{ color: "#002F6C" }}
            >
              {texts.password.back_to_signin}
            </Link>
            <LoadingButton
              loading={busy}
              variant="submit"
              sx={{ mt: 2 }}
              type="submit"
            >
              {texts.password.request_change_password}
            </LoadingButton>
          </Stack>
        </CustomForm>
      ) : (
        <Stack spacing={4}>
          <Typography variant="title" component="h4" align="left">
            {texts.password.forgot_password_done}
          </Typography>
          <Typography component="h4" align="left" sx={{ mb: 6 }}>
            {texts.password.request_change_password_done}
          </Typography>
          <Button
            variant="submit"
            sx={{ mt: 2 }}
            type="submit"
            component={RouterLink}
            to="/login-as-registered"
          >
            {texts.password.back_to_signin}
          </Button>
        </Stack>
      )}
    </LoginLayout>
  );
}
