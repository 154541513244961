import { createSlice } from "@reduxjs/toolkit";
import translations from "../languages/languages.json";
import dateFormats from "../languages/dateFormats.json";
import * as Sentry from "@sentry/react";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../constants/sharedConstants";

let currentLanguage = localStorage.getItem("language") || DEFAULT_LANGUAGE;
if (!SUPPORTED_LANGUAGES.includes(currentLanguage)) {
  currentLanguage = DEFAULT_LANGUAGE;
}

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: currentLanguage,
    texts: translations[currentLanguage],
    dateFormats: dateFormats[currentLanguage],
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      state.texts = translations[action.payload];
      state.dateFormats = dateFormats[state.language];
      Sentry.configureScope((scope) => {
        scope.setTag("page_locale", action.payload);
      });
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
