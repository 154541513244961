import {
  Stack,
  TextField,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getOtp } from "../../service/omvApi";
import LoginLayout from "../../layouts/LoginLayout";
import { setPhone } from "../../state/phoneSlice";
import { safe } from "../../util";
import CustomForm from "../../components/CustomForm";
import { useAuthenticateNavigating } from "../../hooks/useAuthenticateNavigating";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { PHONE_DEFAULT_COUNTRY } from "../../constants/sharedConstants";
import { selectCountryPhoneCodes } from "../../state/countryCodeSlice";

export default function LoginWithPhoneNumber() {
  const { texts } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  useAuthenticateNavigating(false);
  const navigate = useNavigate();

  const [country, setCountry] = useState(PHONE_DEFAULT_COUNTRY);
  const [countryOption, setCountryOption] = useState(undefined);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [busy, setBusy] = useState(false);

  const countryOptions = useSelector(selectCountryPhoneCodes);

  const onCountrySelectChange = (event) => {
    setCountry(event.target.value);
    const selectedCountry = countryOptions.find(
      (item) => item.value === event.target.value,
    );
    setCountryOption(selectedCountry);
  };

  useEffect(() => {
    const selectedCountry = countryOptions.find(
      (item) => item.value === country,
    );
    setCountryOption(selectedCountry);
  }, []);

  const handlePhoneNumberChange = (event) => {
    if (/^[1-9][0-9]*$/.test(event.target.value) || event.target.value === "") {
      setPhoneNumber(event.target.value);
    }
  };

  const onSignin = () =>
    safe(async () => {
      setBusy(true);
      const phone = countryOption.phonePrefix + phoneNumber;
      await getOtp({ phoneNo: phone });
      dispatch(setPhone(phone));
      navigate("/authenticate?registered=0");
    }).finally(() => {
      setBusy(false);
    });

  return (
    <LoginLayout>
      <CustomForm onSubmit={onSignin}>
        <Stack spacing={4}>
          <Typography variant="title" component="h4" align="left">
            {texts.common.signin}
          </Typography>
          <Typography component="h5" align="left" sx={{ mb: 6 }}>
            {texts.common.personal_account}
          </Typography>
          <Grid container alignItems="flex-end">
            <Grid item xs={4} style={{ paddingLeft: 0 }}>
              <FormControl fullWidth>
                <Select
                  labelId="country-label"
                  value={country}
                  onChange={onCountrySelectChange}
                  disabled={busy}
                >
                  {countryOptions.map((option, index) => (
                    <MenuItem
                      value={option.value}
                      key={`${option.value}${index}`}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          className={`fi fi-${option.value.toLowerCase()}`}
                          style={{ width: 20, marginRight: 8 }}
                        />
                        {option.label}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                sx={{ marginLeft: "10px" }}
                label={texts.common.mobile_num}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputProps={{ maxLength: 10 }}
                required
                disabled={busy}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      disableTypography
                      disablePointerEvents
                    >
                      {countryOption?.phonePrefix}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <LoadingButton variant="submit" type="submit" loading={busy}>
            {texts.common.verify_account}
          </LoadingButton>
        </Stack>
      </CustomForm>
    </LoginLayout>
  );
}
