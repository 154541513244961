import * as React from "react";
import { Outlet } from "react-router-dom";
import { useAuthenticateNavigating } from "../../hooks/useAuthenticateNavigating";
import AppLayout from "../../layouts/AppLayout";

export function AuthenticatedPage() {
  useAuthenticateNavigating();

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}
