import { Box } from "@mui/material";
import React, { memo } from "react";

export const VoucherStatus = memo(function VoucherStatus(props) {
  const { params } = props;

  const statusToColor = {
    AC: "green",
    SU: "orange",
    BL: "red",
    RF: "red",
  };
  const color = statusToColor[params.row.voucherStatus] || "gray";
  return <Box sx={{ color }}>{params.row.voucherStatusName}</Box>;
});
