import { createTheme } from "@mui/material/styles";
import {
  bgBG as coreBgBG,
  enUS as coreEnUS,
  roRO as coreRoRO,
} from "@mui/material/locale";

import { DEFAULT_LANGUAGE } from "./constants/sharedConstants";
import { bgBG as dataGridRoBG } from "@mui/x-data-grid/locales/bgBG";
import { enUS as dataGridEnUS } from "@mui/x-data-grid/locales/enUS";
import { enUS as pickersEnUS } from "@mui/x-date-pickers/locales/enUS";
import { roRO as dataGridRoRO } from "@mui/x-data-grid/locales/roRO";
import { roRO as pickersRoRO } from "@mui/x-date-pickers/locales/roRO";

const language = localStorage.getItem("language") || DEFAULT_LANGUAGE;
let localization = [];
switch (language) {
  case "BG":
  case "bg":
    localization = [dataGridRoBG, pickersEnUS, coreBgBG];
    break;
  case "RO":
  case "ro":
    localization = [dataGridRoRO, pickersRoRO, coreRoRO];
    break;
  case "EN":
  case "en":
  default:
    localization = [dataGridEnUS, pickersEnUS, coreEnUS];
}

const theme = createTheme(
  {
    components: {
      MuiCheckbox: {
        variants: [
          {
            props: { variant: "gray" },
            style: {
              color: "#778CA2",
              "&.Mui-checked": {
                color: "#778CA2",
              },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "submit" },
            style: {
              backgroundColor: "#0E9C63",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#0E9C63",
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              backgroundColor: "#FDA120",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FDA120",
              },
            },
          },
          {
            props: { variant: "gray" },
            style: {
              backgroundColor: "#CDD8C8",
              color: "rgb(44, 62, 80)",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#CDD8C8",
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              backgroundColor: "#FDA120",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FDA120",
              },
            },
          },
          {
            props: { variant: "action" },
            style: {
              backgroundColor: "#0E9C63",
              color: "white",
              fontSize: "12px",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#0E9C63",
              },
              "&:disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              },
            },
          },
        ],
      },

      MuiPaper: {
        variants: [
          {
            props: {
              variant: "container",
            },
            style: {
              padding: "24px",
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "title",
            },
            style: {
              color: "rgba(24, 24, 25, 0.9)",
              fontWeight: "bold",
              fontSize: "1.5rem",
              lineHeight: 1.5,
              marginBottom: "16px",
              fontFamily: "Arial, Helvetica, sans-serif",
            },
          },
          {
            props: {
              variant: "14",
            },
            style: {
              color: "rgba(24, 24, 25, 0.9)",
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: 1.5,
            },
          },
          {
            props: {
              variant: "12",
            },
            style: {
              color: "rgba(24, 24, 25, 0.9)",
              fontSize: "12px",
              lineHeight: 1.5,
            },
          },
        ],
      },
    },
  },
  ...localization,
);
export default theme;
