import axios from "axios";
import { REACT_APP_API_URL } from "../constants/sharedConstants";
import { getToken } from "../util";

axios.defaults.withCredentials = true;
const adapter = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 60 * 1000 * 10, // 10 mins
  withCredentials: true,
});

// Add a request interceptor
adapter.interceptors.request.use(
  (request) => {
    const language = getAccessLanguage(localStorage.getItem("language"));
    request.headers.set("Authorization", "Bearer " + getToken());
    request.headers.set("Accept-Language", language);
    request.headers.set("X-TENANTID", process.env.REACT_APP_TENANT_ID);

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

adapter.interceptors.response.use(
  (response) => {
    if (response.status > 399) {
      return Promise.reject(response);
    }
    return response;
  },

  (error) => {
    const currentPath = window.location.href;
    //In case of authentication/authorization failure, and use is not on one of the following pages, redirect them to login page
    //Most likely scenario: Session Expired or user is trying to access an url which is he isn't allowed to access
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      !(
        currentPath.includes("/login") ||
        currentPath.includes("/login-as-registered") ||
        currentPath.includes("/reset-password") ||
        currentPath.includes("/authenticate-as-registered") ||
        currentPath.includes("/order-unauthenticated-accept-terms")
      )
    ) {
      window.location.href = "/login-as-registered";
    }
    return Promise.reject(error?.response?.data?.errorMessage);
  },
);

const getAccessLanguage = (lang) => {
  //Mapping between language codes in app and values used in http header Accept-Language
  switch (lang) {
    case "EN":
      return "en-US";
    case "RO":
      return "ro-RO";
    case "BG":
      return "bg-BG";
    default:
      return "ro-RO";
  }
};

export default adapter;
