import { DataGrid } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import MobileListView from "./MobileListView";
import { NoDataOverlay } from "./components/NoDataOverlay";

const CustomDataGrid = (props) => {
  const { columns, rows, showPageSizeOptions = true, ...otherProps } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return isMobile ? (
    <MobileListView {...props} />
  ) : (
    <DataGrid
      checkboxSelection={false}
      disableColumnFilter
      disableColumnMenu
      rows={rows}
      sx={{
        mt: 4,
        mb: 4,
        "--DataGrid-overlayHeight": "500px",
      }}
      columns={columns}
      pageSizeOptions={showPageSizeOptions ? [5, 10, 20, 50, 100] : [10]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      autoHeight
      slots={{
        noRowsOverlay: NoDataOverlay,
      }}
      {...otherProps}
    />
  );
};

CustomDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

export default CustomDataGrid;
