import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

const Layout = ({ children, header }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundImage: "url(/login-background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        padding: "100px 15px",
        overflow: "auto",
      }}
    >
      {header}
      <Box sx={{ mb: "25px" }}>
        <img
          src="/logo.svg"
          alt="OMV Logo"
          style={{ width: 140, height: 100 }}
        />
      </Box>
      <Paper
        sx={{
          maxWidth: "470px",
          textAlign: "center",
          width: isMobile ? "90%" : "100%",
          borderRadius: "16px",
          padding: isMobile ? "12px" : "52px",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default Layout;
