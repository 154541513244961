import React, { memo } from "react";
import { Dialog, DialogContent, Divider, useMediaQuery } from "@mui/material";
import { EVoucherModalHeader } from "./EVoucherModalHeader";
import { OrderModalInfo } from "./OrderModalInfo";
import EVoucherList from "../../../evoucher/EVoucherList";

export const OrderModal = memo(function OrderModal(props) {
  const { eVoucherModalOpen, handleCloseEVoucherModal, orderItem } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={eVoucherModalOpen}
      onClose={handleCloseEVoucherModal}
      maxWidth={"md"}
    >
      <EVoucherModalHeader {...props} />
      <DialogContent dividers>
        <OrderModalInfo orderItem={orderItem} />
        <Divider />
        <EVoucherList
          orderGuid={orderItem.guid}
          dataGridConfig={{ showPageSizeOptions: !isMobile }}
        />
      </DialogContent>
    </Dialog>
  );
});
