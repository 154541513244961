import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useMemo, useRef } from "react";
import {
  ListItemPaper,
  ValueWrapper,
} from "./components/MobileListView.styled";
import { NoDataOverlay } from "./components/NoDataOverlay";

const MobileListView = (props) => {
  const {
    columns,
    rows,
    paginationModel,
    onPaginationModelChange,
    loading,
    showPageSizeOptions,
    rowCount,
  } = props;
  const topListAnchor = useRef(null);

  const handleChangePage = (event, newPage) => {
    onPaginationModelChange({
      page: newPage,
      pageSize: paginationModel?.pageSize || 10,
    });
    topListAnchor?.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10);
    handleChangePage({
      page: 1,
      pageSize,
    });
  };

  return (
    <>
      {rows.length === 0 ? (
        <Box maxHeight={500} height={500}>
          <NoDataOverlay />
        </Box>
      ) : (
        <>
          <div ref={topListAnchor} id={"mobile-top-anchor"} />
          {rows.map((row, index) => (
            <ListItem row={row} columns={columns} key={index} />
          ))}

          <TablePagination
            component="div"
            count={rowCount}
            page={paginationModel?.page || 0}
            onPageChange={handleChangePage}
            rowsPerPage={paginationModel?.pageSize || 10}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={
              showPageSizeOptions
                ? undefined
                : [paginationModel?.pageSize || 10]
            }
          />
        </>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const ListItem = (props) => {
  const { row, columns } = props;

  const itemFields = useMemo(() => {
    return columns.map((column) => {
      const valueProperty = column.field;
      return {
        label: column.headerName,
        value: row[valueProperty],
        renderCell: column.renderCell,
        type: column.cellType,
        rawData: { row },
      };
    });
  }, [row, columns]);

  const actions = itemFields.filter((itemField) => itemField.type === "action");

  return (
    <ListItemPaper sx={{ minWidth: 275 }} elevation={2}>
      <Grid container spacing={1}>
        {itemFields
          .filter((itemField) => itemField.type !== "action")
          .map((itemField, index) => (
            <React.Fragment key={`field_${index}`}>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{itemField.label}</Typography>
              </Grid>
              <Grid item xs={6}>
                <ListItemFieldValue itemField={itemField} />
              </Grid>
            </React.Fragment>
          ))}
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          width={"100%"}
          marginTop={"10px"}
        >
          {actions.map((action, index) => (
            <React.Fragment key={`action_${index}`}>
              {action.renderCell ? action.renderCell(action.rawData) : null}
            </React.Fragment>
          ))}
        </Stack>
      </Grid>
    </ListItemPaper>
  );
};

const ListItemFieldValue = (props) => {
  const { itemField } = props;
  switch (itemField.type) {
    case "text":
    case "date":
      return (
        <ValueWrapper>
          {itemField.renderCell
            ? itemField.renderCell(itemField.rawData)
            : itemField.value}
        </ValueWrapper>
      );
  }
  return null;
};

export default MobileListView;
