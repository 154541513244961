import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Close from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getUserProfile, login } from "../../service/omvApi";
import LoginLayout from "../../layouts/LoginLayout";
import { safe, setToken } from "../../util";
import CustomForm from "../../components/CustomForm";
import { setPhone } from "../../state/phoneSlice";
import { useAuthenticateNavigating } from "../../hooks/useAuthenticateNavigating";

export default function LoginAsRegistered() {
  const { texts } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuthenticateNavigating(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [busy, setBusy] = useState(false);

  const submitLogin = () =>
    safe(async () => {
      setBusy(true);
      const response = await login({ username, password });
      setToken(response.data.token);
      if (response.data.maskedPhone) {
        dispatch(setPhone(response.data.maskedPhone));
        navigate("/authenticate?registered=1");
      } else {
        await getUserProfile();
        navigate("/order-list");
      }
    }).finally(() => {
      setBusy(false);
    });

  return (
    <LoginLayout>
      <CustomForm onSubmit={submitLogin}>
        <Stack spacing={4}>
          <Typography variant="title" component="h4" align="left">
            {texts.common.signin}
          </Typography>
          <TextField
            fullWidth
            autoComplete={"username"}
            label={texts.common.username}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            inputProps={{}}
            InputProps={{
              endAdornment: username && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setUsername("")} tabIndex={-1}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              maxLength: 30,
            }}
            disabled={busy}
          />
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            autoComplete={"password"}
            label={texts.common.password}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              maxLength: 30,
            }}
            disabled={busy}
          />
          <Link
            component={RouterLink}
            to="/forgot-password"
            variant="body2"
            align="right"
            sx={{ color: "#002F6C" }}
          >
            {texts.password.forgot_password}
          </Link>
          <LoadingButton
            variant="submit"
            sx={{ mt: 2 }}
            type="submit"
            loading={busy}
          >
            {texts.common.verify_account}
          </LoadingButton>
        </Stack>
      </CustomForm>
    </LoginLayout>
  );
}
