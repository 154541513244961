import { Button } from "@mui/material";
import React, { memo } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const ViewEVoucherButton = memo(function ViewEVoucherButton(props) {
  const { params, label, onViewEVoucher } = props;

  return (
    <Button
      variant="action"
      onClick={() => onViewEVoucher(params.row)}
      startIcon={<VisibilityIcon />}
    >
      {label}
    </Button>
  );
});
