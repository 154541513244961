import { styled } from "@mui/material/styles";

export const VerifiedMessageStyled = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: "#F1FFF9",
  border: "1px solid #0E9C63",
  borderRadius: theme.spacing(2.5),
  margin: "0 auto",
  width: "100%",
  img: {
    width: "5.3125rem",
    height: "5.3125rem",
  },
  h2: {
    textAlign: "center",
    letterSpacing: " 0.3px",
    textTransform: "uppercase",
    color: "#263238",
    fontWeight: 700,
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
}));
