import { removeToken } from "../util";

export const useAuthentication = () => {
  const handleLogout = () => {
    removeToken();
    localStorage.removeItem("guid");
  };

  return {
    handleLogout,
  };
};
