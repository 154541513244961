import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import fromUnixTime from "date-fns/fromUnixTime";

import jwtDecode from "jwt-decode";
import {
  ROLE_ADMINISTRATOR,
  ROLE_SMS_VERIFYING_USER,
} from "./constants/sharedConstants";
import { toast } from "react-toastify";

export const saveBlobToFile = (blobStore, fileName) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blobStore);
  link.download = fileName;
  link.click();
};

export const getFileName = (response) => {
  let filename = "";
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
    return filename;
  }
  return "";
};

// { a: 1, b: 2, c: [3, 4] } => "a=1&b=2&c=3&c=4"
export const getQueryParams = (paramsObj) => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(paramsObj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, item);
      });
    } else if (value) {
      params.append(key, value);
    }
  }
  return params;
};

// null safe date fns format
export const safeFormat = (date, dateFormat) => {
  if (!date) return null;
  const parsedDate = toDate(date, { timeZone: "UTC" }); // make sure date is a string in ISO format
  return format(parsedDate, dateFormat);
};

// "HELLO world" => "Hello World"
export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const validateGuid = (guid) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(guid);
};

export const getGuid = () => {
  return localStorage.getItem("guid");
};

export const setGuid = (guid) => {
  localStorage.setItem("guid", guid);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const hasAdminRole = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const payload = jwtDecode(token);
  return (
    payload?.authorities.length &&
    payload?.authorities.includes(ROLE_ADMINISTRATOR)
  );
};

/**
 * Return TRUE if User is in Verifying token process
 */
export const isVerifyingToken = (token) => {
  if (!token) {
    return false;
  }
  const payload = jwtDecode(token);
  if (payload?.exp && isTokenExpired(payload)) {
    return false;
  }
  return isParsedTokenContainsAuthority(payload, ROLE_SMS_VERIFYING_USER);
};

/**
 * Return TRUE if token valid.
 * Token valid when not expired and not contains ROLE_SMS_VERIFYING_USER authority
 */
export const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const payload = jwtDecode(token);
  return (
    !isTokenExpired(payload) &&
    !isParsedTokenContainsAuthority(payload, ROLE_SMS_VERIFYING_USER)
  );
};

/**
 * Return TRUE if token is expired
 */
const isTokenExpired = (decodePayload) => {
  if (!decodePayload || decodePayload.exp === undefined) {
    return true;
  }
  return new Date() >= fromUnixTime(decodePayload.exp);
};

export const isTokenContainsAuthority = (token, authority) => {
  return isParsedTokenContainsAuthority(jwtDecode(token), authority);
};

/**
 * Return TRUE if token has ROLE_SMS_VERIFYING_USER authority
 */
const isParsedTokenContainsAuthority = (decodePayload, authority) => {
  if (!decodePayload) {
    return false;
  }
  return (
    decodePayload?.authorities.length &&
    decodePayload?.authorities.includes(authority)
  );
};

export const downloadAndSaveFile = (response, fileName, ext) => {
  saveBlobToFile(response, fileName + "." + ext.toLowerCase());
};

export const urlByRole = (url) => {
  if (hasAdminRole()) {
    return "/admin" + url;
  }
  return url;
};

export const matrixToJsonList = (fields, data) => {
  const gridColumnConfig = [];
  data.forEach((column) => {
    const columnConfigEntry = {};
    column.forEach((property, propertyIndex) => {
      columnConfigEntry[fields[propertyIndex]] = property;
    });
    gridColumnConfig.push(columnConfigEntry);
  });
  return gridColumnConfig;
};

export const safe = async (func, ...args) => {
  try {
    await func(...args);
  } catch (e) {
    toast.error(e?.errorMessage || e);
  }
};

/**
 * Return TRUE is password is valid
 */
export const validatePassword = (password) => {
  if (!password) {
    return false;
  }
  if (password.length < 8) {
    return false;
  }
  if (password.search(/\d/) === -1) {
    return false;
  }
  return password.search(/[a-zA-Z]/) !== -1;
};
