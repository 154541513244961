import { useSelector } from "react-redux";
import React, { useMemo, useRef } from "react";
import { IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  DownloadEVouchersButton,
  DownloadInvoicesButton,
  OrderModal,
  ViewEVoucherButton,
} from "./components";
import CustomDataGrid from "../../components/list-view/CustomDataGrid";
import { getOrders } from "../../service/omvApi";
import { safe } from "../../util";
import CustomForm from "../../components/CustomForm";
import DateTableCell from "../../components/tableCell/DateTableCell";
import { useDataGrid } from "../../hooks/useDataGrid";

export default function OrderList() {
  const { texts, language } = useSelector((state) => state.language);
  const [tableData, setTableData] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [eVoucherModalOpen, setEVoucherModalOpen] = useState(false);
  const selectedOrder = useRef(undefined);

  const columns = useMemo(
    () => [
      {
        field: "index",
        headerName: texts.header.order.nr_ctr,
        minWidth: 75,
        flex: 1,
        cellType: "text",
      },
      {
        field: "orderDate",
        headerName: texts.header.order.data_com,
        minWidth: 125,
        flex: 1,
        cellType: "date",
        renderCell: (props) => (
          <DateTableCell {...props} fieldName={"orderDate"} />
        ),
      },
      {
        field: "orderNo",
        headerName: texts.header.order.nr_com,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "orderTypeName",
        headerName: texts.header.order.tip_com,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "orderStatusName",
        headerName: texts.header.order.store_com,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "voucherCount",
        headerName: texts.header.order.nr_be,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "voucherTotalValue",
        headerName: texts.header.order.vol_tota,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "invoiceNumber",
        headerName: texts.header.order.nr_fac,
        minWidth: 125,
        flex: 1,
        cellType: "text",
      },
      {
        field: "viewEVoucher",
        headerName: texts.header.order.vezi_com,
        minWidth: 125,
        flex: 1,
        cellType: "action",
        sortable: false,
        renderCell: (params) => (
          <ViewEVoucherButton
            params={params}
            label={texts.order.eVouchers}
            onViewEVoucher={onViewEVoucher}
          />
        ),
      },
      {
        field: "downloadOrder",
        headerName: texts.order.downloadEVoucher,
        flex: 1,
        minWidth: 125,
        cellType: "action",
        sortable: false,
        renderCell: (params) => (
          <DownloadEVouchersButton
            guid={params.row.guid}
            label={texts.order.eVoucher}
          />
        ),
      },
      ...(process.env.REACT_APP_INVOICE_ENABLED === "true"
        ? [
            {
              field: "downloadInvoice",
              headerName: texts.order.downloadInvoice,
              flex: 1,
              minWidth: 125,
              cellType: "action",
              sortable: false,
              renderCell: (params) => (
                <DownloadInvoicesButton
                  params={params}
                  label={texts.order.invoice}
                />
              ),
            },
          ]
        : []),
    ],
    [language],
  );

  const onFetchOrders = (pagination, sortModel) =>
    safe(async () => {
      setLoading(true);
      const sortItem = sortModel?.length ? sortModel[0] : undefined;
      const params = {
        page: pagination.page ? pagination.page + 1 : 1,
        rows: pagination.pageSize || 10,
        sidx: sortItem?.field,
        sord: sortItem?.sort ? sortItem.sort.toUpperCase() : undefined,
        searchString: query,
      };

      const res = await getOrders(params);
      setRowCount(res.records || 0);
      const items = res.rows.map((item, i) => {
        item.index = i + 1;
        item.voucherTotalValue += " " + item.currency;
        return item;
      });
      setTableData(items);
    }).finally(() => {
      setLoading(false);
    });

  const {
    paginationModel,
    rowCount,
    setRowCount,
    sortModel,
    onPaginationModelChange,
    onFilterQuery,
    onSortModelChange,
  } = useDataGrid({
    queryCallback: onFetchOrders,
  });

  useEffect(() => {
    onFetchOrders(paginationModel);
  }, []);

  const onViewEVoucher = (orderItem) => {
    setEVoucherModalOpen(true);
    selectedOrder.current = orderItem;
  };

  const handleCloseEVoucherModal = () => {
    setEVoucherModalOpen(false);
    selectedOrder.current = undefined;
  };

  return (
    <>
      <Typography variant="title">{texts.common.orders}</Typography>
      <CustomForm
        onSubmit={onFilterQuery}
        style={{ display: "flex", alignItems: "center" }}
        autoComplete={"off"}
      >
        <TextField
          label={texts.orderView.order_search_by}
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{
            marginRight: "8px",
            marginTop: "8px",
            width: "min(70vw, 350px)",
          }}
          inputProps={{ maxLength: 20 }}
          autoComplete={"off"}
        />
        <IconButton type="submit" style={{ alignSelf: "center" }}>
          <SearchIcon />
        </IconButton>
      </CustomForm>

      {selectedOrder.current && (
        <OrderModal
          eVoucherModalOpen={eVoucherModalOpen}
          handleCloseEVoucherModal={handleCloseEVoucherModal}
          orderItem={selectedOrder.current}
        />
      )}

      <CustomDataGrid
        columns={columns}
        rows={tableData}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        paginationMode={"server"}
        rowCount={rowCount}
        keepNonExistentRowsSelected
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        hideFooterSelectedRowCount
        disableRowSelectionOnClick
      />
    </>
  );
}
