import { createSlice } from "@reduxjs/toolkit";

export const eVoucherSlice = createSlice({
  name: "applicationControl",
  initialState: {
    selectedRows: [],
  },
  reducers: {
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
  },
});

export const { setSelectedRows } = eVoucherSlice.actions;

export const selectSelectedEVoucherRows = (state) =>
  state.eVoucher.selectedRows;

export const eVoucherReducer = eVoucherSlice.reducer;
