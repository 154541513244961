import { Typography, Paper, Divider, Grid, Box, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getCustomerInfo } from "../service/omvApi";

const styles = {
  paper: {
    p: 4,
    height: "100%",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#778CA2",
  },
  wideLabel: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#778CA2",
    width: "150px",
    display: "inline-block",
  },
  value: {
    fontSize: "16px",
    color: "#003669",
    display: "inline-block",
  },
  bigValue: {
    fontSize: "32px",
    color: "#003669",
    fontWeight: "700",
  },
  titleParens: {
    color: "#92929D",
  },
};

export default function CustomerData() {
  const { texts } = useSelector((state) => state.language);
  const customerData = useSelector((state) => state.customerData);
  useEffect(() => {
    getCustomerInfo();
  }, []);
  return (
    <>
      <Typography variant="title" component="div" sx={{ marginBottom: "16px" }}>
        {texts.common.customer_data}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={styles.paper}>
            <Stack spacing={2}>
              <Typography variant="h6">
                <b>{texts.customer_detail.title}</b>{" "}
                <span style={styles.titleParens}>
                  ({texts.customer_detail.invoicing})
                </span>
              </Typography>
              <Divider />
              <Typography sx={styles.label}>
                {texts.customer_detail.name}
              </Typography>
              <Typography sx={styles.bigValue}>
                {customerData?.customer?.name}
              </Typography>
              <Typography sx={styles.label}>
                {texts.customer_detail.client_no}
              </Typography>
              <Typography sx={styles.bigValue}>
                {customerData?.customer?.custCode}
              </Typography>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.addr}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.customer?.address}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.country}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.customer?.country}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.county}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.customer?.county}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.street}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.customer?.street}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.zipCode}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.customer?.zipCode}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={styles.paper}>
            <Stack spacing={2}>
              <Typography variant="h6">
                <b>{texts.customer_detail.client}</b>{" "}
                <span style={styles.titleParens}>
                  ({texts.consinee_detial.consignee})
                </span>
              </Typography>
              <Divider />
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.client_name}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.name}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.client_no}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.custCode}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.del_address}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.address}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.country}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.country}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.county}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.county}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.street}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.street}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.zipCode}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.consignee?.zipCode}
                </Typography>
              </Box>
              <Divider />
              <Typography sx={styles.label}>
                {texts.customer_detail.cont_person}
              </Typography>
              <Typography sx={styles.bigValue}>
                {customerData?.contactPerson?.personName}
              </Typography>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.phone}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.contactPerson?.phone}
                </Typography>
              </Box>
              <Box>
                <Typography sx={styles.wideLabel}>
                  {texts.customer_detail.email}
                </Typography>
                <Typography sx={styles.value}>
                  {customerData?.contactPerson?.email}
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
