import React, { useState } from "react";
import { clone } from "lodash";

export const useDataGrid = ({ queryCallback }) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortModel, setSortModel] = useState([]);

  const isSortModelChange = (oldValue, newValue) => {
    if (oldValue.length !== newValue.length) {
      return true;
    }
    return oldValue[0]?.field !== newValue[0]?.field;
  };

  const onSortModelChange = (newSortModel) => {
    let updatePagination = clone(paginationModel);
    if (isSortModelChange(sortModel, newSortModel)) {
      updatePagination.page = 0;
    }
    setPaginationModel(updatePagination);
    setSortModel(newSortModel);
    console.log("queryCallback", { updatePagination, sortModel });
    queryCallback(updatePagination, newSortModel);
  };

  const onPaginationModelChange = (value) => {
    let updatePagination = clone(value);
    if (paginationModel.pageSize !== value.pageSize) {
      updatePagination.page = 0;
    }
    setPaginationModel(updatePagination);
    queryCallback(updatePagination, sortModel);
  };

  const onFilterQuery = () => {
    const initPagination = {
      pageSize: paginationModel.pageSize,
      page: 0,
    };
    setPaginationModel(initPagination);
    setSelectedRows([]);
    queryCallback(initPagination, sortModel);
  };

  return {
    paginationModel,
    setPaginationModel,
    rowCount,
    setRowCount,
    selectedRows,
    setSelectedRows,
    sortModel,
    setSortModel,
    onPaginationModelChange,
    onFilterQuery,
    onSortModelChange,
  };
};
