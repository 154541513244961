import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: null,
  reducers: {
    setUserInfo: (state, action) => {
      Sentry.setUser({
        email: action.payload.email,
      });
      return action.payload;
    },
  },
});

export const { setUserInfo } = userInfoSlice.actions;

export const selectUserInfo = (state) => state.userInfo;

export default userInfoSlice.reducer;
