import { useSelector } from "react-redux";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Menu, MenuItem, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { downloadVoucher } from "../../../service/omvApi";
import { downloadAndSaveFile, safe } from "../../../util";
import { selectSelectedEVoucherRows } from "../../../state/evoucherSlice";

export const ExportVouchers = (props) => {
  const { count } = props;
  const { texts } = useSelector((state) => state.language);
  const selectedRows = useSelector(selectSelectedEVoucherRows);
  const [openMenu, setOpenMenu] = useState(false);
  const [busy, setBusy] = useState(false);

  const [anchorExportEl, setAnchorExportEl] = useState(null);

  const handleExportMenu = (event) => {
    if (anchorExportEl == null) {
      setAnchorExportEl(event.currentTarget);
    }
    setOpenMenu(true);
  };
  const closeExportMenu = () => setOpenMenu(false);

  const onBulkDownloadVouchers = (fileType) =>
    safe(async () => {
      setOpenMenu(false);
      setBusy(true);
      if (selectedRows.length === 0) {
        toast.error(texts.common.select_at_least_one_row);
        return;
      }
      const downloadInfo = {
        format: fileType,
        serialNos: selectedRows,
      };
      const blob = await downloadVoucher(downloadInfo);
      downloadAndSaveFile(
        blob,
        "evouchers_" + Math.floor(Date.now() / 1000),
        fileType,
      );
    }).finally(() => {
      setBusy(false);
    });

  if (count === 0) {
    return null;
  }

  return (
    <ExportVoucherWrapper>
      <Typography
        component="span"
        sx={{
          marginRight: "10px",
        }}
      >
        {texts.common.number_of_rows_selected}&nbsp;{selectedRows.length}
      </Typography>
      <LoadingButton loading={busy} onClick={handleExportMenu} variant="action">
        {texts.common.export_selected_rows}
      </LoadingButton>
      <Menu
        anchorEl={anchorExportEl}
        open={anchorExportEl !== null && openMenu}
        onClose={closeExportMenu}
      >
        <MenuItem onClick={() => onBulkDownloadVouchers("CSV")}>CSV</MenuItem>
        <MenuItem onClick={() => onBulkDownloadVouchers("PDF")}>PDF</MenuItem>
      </Menu>
    </ExportVoucherWrapper>
  );
};

export const ExportVoucherWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
