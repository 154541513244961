import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { setLanguage } from "../state/languageSlice";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from "../constants/sharedConstants";

const languages = [
  { label: "RO", flag: "/ro-flag.svg" },
  { label: "BG", flag: "/bg-flag.svg" },
  { label: "EN", flag: "/en-flag.svg" },
].filter((lang) => SUPPORTED_LANGUAGES.includes(lang.label));

const labelToFlag = languages.reduce((acc, curr) => {
  acc[curr.label] = curr.flag;
  return acc;
}, {});

export default function ChooseLanguage() {
  const dispatch = useDispatch();
  const localStorageLanguage =
    localStorage.getItem("language") || DEFAULT_LANGUAGE;
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: localStorageLanguage,
    flag: labelToFlag[localStorageLanguage],
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    dispatch(setLanguage(lang));

    //Reload page in order to reload data fetched by API which is language dependent
    window.location.reload();
  };
  return (
    <Box>
      <Button
        sx={{
          color: "rgb(0, 47, 108)",
          border: "1px solid rgb(230, 231, 232)",
          borderRadius: 5,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {selectedLanguage.flag && (
          <img src={selectedLanguage.flag} alt="" height="30" />
        )}
        &nbsp;
        {selectedLanguage.label}
        {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.label}
            onClick={() => {
              setSelectedLanguage(language);
              changeLanguage(language.label);
              setAnchorEl(null);
            }}
          >
            <img src={language.flag} alt="" height="30" />
            &nbsp; {language.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
