import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getToken, isValidToken } from "../../util";

export const NotFoundPage = () => {
  const navigation = useNavigate();
  const token = getToken();

  useEffect(() => {
    isValidToken(token)
      ? navigation("/order-list")
      : navigation("/order-unauthenticated-accept-terms");
  }, [token]);

  return null;
};
