import React, { memo, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { downloadAndSaveFile, safe } from "../../../util";
import { downloadVoucher } from "../../../service/omvApi";

export const DownloadEVouchersButton = memo(
  function DownloadEVouchersButton(props) {
    const { guid, label } = props;
    const { texts } = useSelector((state) => state.language);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [busy, setBusy] = useState(false);

    const handleMenu = (event) => {
      if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
      }
      setOpenMenu(true);
    };
    const closeMenu = () => {
      setOpenMenu(false);
    };

    const onDownloadVouchers = (ext, guid) =>
      safe(async () => {
        if (!guid) {
          return;
        }
        setOpenMenu(false);
        setBusy(true);
        const downloadInfo = {
          guid,
          format: ext,
        };
        const blob = await downloadVoucher(downloadInfo);
        downloadAndSaveFile(
          blob,
          `evouchers_` + Math.floor(Date.now() / 1000),
          ext,
        );
      }).finally(() => {
        setBusy(false);
      });

    return (
      <>
        <LoadingButton
          onClick={handleMenu}
          variant="action"
          startIcon={<DownloadIcon />}
          loading={busy}
          loadingIndicator={texts.common.downloading}
        >
          {label}
        </LoadingButton>
        <Menu
          anchorEl={anchorEl}
          open={anchorEl !== null && openMenu}
          onClose={closeMenu}
        >
          <MenuItem onClick={() => onDownloadVouchers("CSV", guid)}>
            CSV
          </MenuItem>
          <MenuItem onClick={() => onDownloadVouchers("PDF", guid)}>
            PDF
          </MenuItem>
        </Menu>
      </>
    );
  },
);
