import React, { memo, useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import DateTableCell from "../../../../components/tableCell/DateTableCell";

export const OrderModalInfo = memo(function OrderModalInfo(props) {
  const { orderItem } = props;
  const { texts } = useSelector((state) => state.language);

  const itemFields = useMemo(
    () => [
      {
        field: "orderDate",
        label: texts.header.order.data_com,
        cellType: "date",
        column: 1,
      },
      {
        field: "orderNo",
        label: texts.header.order.nr_com,
        cellType: "text",
        column: 1,
      },
      {
        field: "orderTypeName",
        label: texts.header.order.tip_com,
        cellType: "text",
        column: 1,
      },
      {
        field: "orderStatusName",
        label: texts.header.order.store_com,
        cellType: "text",
        column: 1,
      },
      {
        field: "voucherCount",
        label: texts.header.order.nr_be,
        cellType: "text",
        column: 1,
      },
      {
        field: "voucherTotalValue",
        label: texts.header.order.vol_tota,
        cellType: "text",
        column: 1,
      },
      {
        field: "customerCode",
        label: texts.orderView.customerCode,
        cellType: "text",
        column: 2,
      },
      {
        field: "customerName",
        label: texts.orderView.customerName,
        cellType: "text",
        column: 2,
      },
      {
        field: "invoiceNumber",
        label: texts.orderView.invoiceNumber,
        cellType: "text",
        column: 2,
      },
    ],
    [],
  );

  return (
    <>
      <OrderInfoWrapper>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ paddingY: "10px" }}>
            <img
              src="/logo-blue.svg"
              alt="ovv logo"
              style={{
                width: 74,
                height: 53,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="half__title">
                  <h4>OMV</h4>
                  <span>{texts.orderView.supplier}</span>
                </div>
              </Grid>
              {itemFields
                .filter((itemField) => itemField.column === 1)
                .map((itemField, index) => (
                  <React.Fragment key={`field_${index}`}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {itemField.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ValueWrapper>
                        <OrderInfoValue
                          itemField={itemField}
                          orderItem={orderItem}
                        />
                      </ValueWrapper>
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColumnGrid container spacing={1}>
              <Grid item xs={12} sx={{}}>
                <div className="half__title">
                  <h4>{orderItem?.customerName}</h4>
                  <span>{texts.orderView.customer}</span>
                </div>
              </Grid>
              {itemFields
                .filter((itemField) => itemField.column === 2)
                .map((itemField, index) => (
                  <React.Fragment key={`field_${index}`}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        {itemField.label}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ValueWrapper>
                        <OrderInfoValue
                          itemField={itemField}
                          orderItem={orderItem}
                        />
                      </ValueWrapper>
                    </Grid>
                  </React.Fragment>
                ))}
            </ColumnGrid>
          </Grid>
        </Grid>
      </OrderInfoWrapper>
    </>
  );
});

const OrderInfoValue = memo(function OrderInfoValue(props) {
  const { itemField, orderItem } = props;
  switch (itemField.cellType) {
    case "date":
      return <DateTableCell row={orderItem} fieldName={itemField.field} />;
    default:
      return orderItem[itemField.field];
  }
});

const OrderInfoWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ValueWrapper = styled(Box)(({ theme }) => ({
  color: "#003669",
  fontWeight: theme.typography.subtitle2.fontWeight,
}));

const ColumnGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    marginTop: "10px",
  },
}));
