import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const ValueWrapper = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
}));

export const ListItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));
