import { createSlice } from "@reduxjs/toolkit";

export const guidSlice = createSlice({
  name: "guid",
  initialState: "",
  reducers: {
    setStateGuid: (state, action) => {
      return action.payload;
    },
  },
});

export const { setStateGuid } = guidSlice.actions;
export const selectOrderGuid = (state) => state.guid;
export default guidSlice.reducer;
