import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Backdrop, CircularProgress, CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useMemo } from "react";

import { bg, ro, enUS } from "date-fns/locale";
import LoginAsRegistered from "./pages/authentication/LoginAsRegistered";
import LoginWithPhoneNumber from "./pages/authentication/LoginWithPhoneNumber";
import Authenticate from "./pages/authentication/Authenticate";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import OrderList from "./pages/order/OrderList";
import EVoucherList from "./pages/evoucher/EVoucherList";
import CustomerData from "./pages/CustomerData";
import OrderAuthenticatedAcceptTerms from "./pages/OrderAuthenticatedAcceptTerms";
import theme from "./theme";
import { getUserProfile } from "./service/omvApi";
import { getToken, isValidToken, safe } from "./util";
import { DEFAULT_LANGUAGE } from "./constants/sharedConstants";
import { AuthenticatedPage } from "./pages/route/AuthenticatedPage";
import { NotFoundPage } from "./pages/route/NotFoundPage";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ActivateAccount from "./pages/authentication/ActivateAccount";
import { isApplicationBusy } from "./state/applicationControlSlice";

export default function App() {
  const { texts } = useSelector((state) => state.language);
  const token = getToken();
  const applicationBusy = useSelector(isApplicationBusy);

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", DEFAULT_LANGUAGE);
    }
    if (token && isValidToken(token)) {
      safe(async () => {
        await getUserProfile();
      });
    }
  }, [token]);

  const language = localStorage.getItem("language") || DEFAULT_LANGUAGE;
  const locale = useMemo(() => {
    switch (language) {
      case "RO":
      case "ro":
        return ro;
      case "BG":
      case "bg":
        return bg;
      default:
        return enUS;
    }
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <Helmet>
          <title>{texts.common.title}</title>
        </Helmet>
        <CssBaseline />
        <ToastContainer position="top-center" />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthenticatedPage />}>
              <Route path="order-list" element={<OrderList />} />
              <Route path="ev-list" element={<EVoucherList />} />
              <Route path="customer-data" element={<CustomerData />} />
            </Route>
            <Route
              path="/order-unauthenticated-accept-terms"
              element={<OrderAuthenticatedAcceptTerms />}
            />
            <Route
              path="/login-as-registered"
              element={<LoginAsRegistered />}
            />
            <Route path="/login" element={<LoginWithPhoneNumber />} />
            <Route path="/authenticate" element={<Authenticate />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:accessCode"
              element={<ResetPassword />}
            />
            <Route path="/activate-account" element={<ActivateAccount />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={applicationBusy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
