import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { ThemeProvider } from "@mui/material/styles";
import {
  REACT_APP_API_URL,
  REACT_APP_SENTRY_DNS,
  REACT_APP_SENTRY_ENABLED,
  REACT_APP_SENTRY_ENV,
} from "./constants/sharedConstants";
import * as Sentry from "@sentry/react";

if (REACT_APP_SENTRY_ENABLED) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DNS,
    environment: REACT_APP_SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [REACT_APP_API_URL],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div style={{ height: "100%" }}>
        <App />
      </div>
      <div style={{ flex: 1 }}></div>
    </ThemeProvider>
  </Provider>,
);
