import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Link,
  InputAdornment,
  IconButton,
  TextField,
  Box,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { VerifiedMessageStyled } from "./components/VerifiedMessage.styled";
import {
  registerPassword,
  requestActivateAccount,
  verifyActivateAccount,
} from "../../service/omvApi";
import LoginLayout from "../../layouts/LoginLayout";
import {
  getToken,
  isTokenContainsAuthority,
  isValidToken,
  safe,
  validatePassword,
} from "../../util";
import CustomForm from "../../components/CustomForm";
import {
  DEFAULT_AUTHENTICATED_PATH,
  DEFAULT_UNAUTHENTICATED_PATH,
  LOGIN_AS_REGISTERED_PATH,
} from "../../constants/pathConstants";
import { ROLE_UNREGISTERED_USER } from "../../constants/sharedConstants";

export default function ActivateAccount() {
  const navigate = useNavigate();
  const token = getToken();
  const { texts } = useSelector((state) => state.language);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [busy, setBusy] = useState(false);
  const [applicationBusy, setApplicationBusy] = useState(false);
  const [verified, setVerified] = useState(false);

  const requestActivate = useCallback(() => {
    safe(async () => {
      setApplicationBusy(true);
      const res = await requestActivateAccount();
      setPhone(res.data);
    }).finally(() => setApplicationBusy(false));
  }, []);

  useEffect(() => {
    if (isValidToken(token)) {
      if (isTokenContainsAuthority(token, ROLE_UNREGISTERED_USER)) {
        requestActivate();
      } else {
        navigate(DEFAULT_AUTHENTICATED_PATH);
      }
    } else {
      navigate(DEFAULT_UNAUTHENTICATED_PATH);
    }
  }, [token, requestActivate]);

  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordState, setPasswordState] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (field) =>
    setPasswordState({
      ...passwordState,
      [field]: !passwordState[field],
    });

  const onOtpComplete = async (value) => {
    verifyOtp(value);
  };

  const submitOtpForm = () => {
    if (otp.length < 5) {
      toast.error(texts.comon.wrong_otp);
      return;
    }
    if (verified) {
      return;
    }
    verifyOtp(otp);
  };

  const submitPasswordForm = () => {
    if (otp.length < 5) {
      toast.error(texts.comon.wrong_otp);
      return;
    }
    if (password.password !== password.confirmPassword) {
      toast.error(texts.message.pass_not_same);
      return;
    }
    if (!validatePassword(password.password)) {
      toast.error(texts.message.pass_not_strength);
      return;
    }

    safe(async () => {
      setBusy(true);
      setApplicationBusy(true);
      await registerPassword({ password: password.password, accessCode: otp });
      toast.success(texts.message.reg_success);
      localStorage.removeItem("token");
      navigate(LOGIN_AS_REGISTERED_PATH);
    }).finally(() => {
      setBusy(false);
      setApplicationBusy(false);
    });
  };

  const verifyOtp = (accessCode) => {
    safe(async () => {
      setBusy(true);
      await verifyActivateAccount({ accessCode });
      setVerified(true);
    }).finally(() => {
      setBusy(false);
    });
  };

  const submitResendOtp = () =>
    safe(async () => {
      setBusy(true);
      await requestActivate();
      toast.success(texts.common.re_sent_otp.replace("{phone}", phone));
    }).finally(() => {
      setBusy(false);
    });

  return (
    <>
      <LoginLayout
        header={
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <Grid
              container
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ padding: "30px 50px" }}
            >
              <Grid item>
                <Typography color={"white"} component="span">
                  {texts.activate_account.already}
                </Typography>
                <Link
                  component={RouterLink}
                  to="/login-as-registered"
                  sx={{ marginLeft: "0.5em", color: "#0E9C63" }}
                >
                  {texts.activate_account.signin}
                </Link>
              </Grid>
            </Grid>
          </Box>
        }
      >
        <CustomForm onSubmit={submitOtpForm} disabled={verified}>
          <Stack>
            <Typography
              variant="title"
              component="h4"
              align="left"
              sx={{ mb: 0 }}
            >
              {texts.common.ver_num}
            </Typography>
            <Typography component="p" align="left" sx={{ mb: 2, marginTop: 0 }}>
              {texts.common.sent_otp} {phone}
            </Typography>
            {verified ? (
              <VerifiedMessageStyled>
                <img
                  className="w-100 d-flex justify-center"
                  src="/verify-icon.svg"
                />
                <h2 className="text-uppercase verified__text">
                  {texts.common.phone_number_verified}
                </h2>
              </VerifiedMessageStyled>
            ) : (
              <>
                <Typography variant={"subtitle2"} align="left" sx={{ mb: 2 }}>
                  {texts.common.enter_otp}
                </Typography>
                <MuiOtpInput
                  value={otp}
                  onChange={(value) => setOtp(value)}
                  validateChar={(value) => /\d/.test(value)}
                  length={5}
                  onComplete={onOtpComplete}
                  autoFocus
                  TextFieldsProps={{
                    disabled: busy || verified,
                  }}
                />
                <Typography component="h4" align="left" sx={{ mb: 3, mt: 2 }}>
                  {texts.common.not_received}
                  <Link
                    component="button"
                    sx={{ ml: 1 }}
                    onClick={submitResendOtp}
                    type="button"
                    disabled={verified}
                  >
                    {texts.common.resend}
                  </Link>
                </Typography>
                <LoadingButton
                  variant="submit"
                  sx={{ mt: 2 }}
                  type="submit"
                  loading={busy}
                  disabled={verified}
                >
                  {texts.common.verify_account}
                </LoadingButton>
              </>
            )}
          </Stack>
        </CustomForm>

        <CustomForm onSubmit={submitPasswordForm}>
          <Stack sx={{ mt: 3 }}>
            <Typography variant={"subtitle2"} align="left" sx={{ mb: 1 }}>
              {texts.common.choose_pass}
            </Typography>
            <TextField
              fullWidth
              required
              size="small"
              autoComplete={"password"}
              type={passwordState.password ? "text" : "password"}
              placeholder={texts.common.enter_pass}
              value={password.password}
              onChange={(e) =>
                setPassword({
                  ...password,
                  password: e.target.value,
                })
              }
              InputProps={{
                endAdornment: password.password && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("password")}
                      onMouseDown={() => handleClickShowPassword("password")}
                      edge="end"
                    >
                      {passwordState.password ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled={busy || !verified}
            />
            <Typography
              variant={"subtitle2"}
              align="left"
              sx={{ mt: 2, mb: 1 }}
            >
              {texts.common.confirm_pass}
            </Typography>
            <TextField
              fullWidth
              required
              autoComplete={"confirm-password"}
              type={passwordState.confirmPassword ? "text" : "password"}
              size="small"
              placeholder={texts.common.enter_pass}
              value={password.confirmPassword}
              onChange={(e) =>
                setPassword({
                  ...password,
                  confirmPassword: e.target.value,
                })
              }
              InputProps={{
                endAdornment: password.confirmPassword && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("confirmPassword")}
                      onMouseDown={() =>
                        handleClickShowPassword("confirmPassword")
                      }
                      edge="end"
                    >
                      {passwordState.confirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled={busy || !verified}
            />
            <LoadingButton
              variant="submit"
              sx={{ mt: 3 }}
              type="submit"
              loading={busy}
              disabled={!verified}
            >
              {texts.common.create_acc}
            </LoadingButton>
          </Stack>
        </CustomForm>
      </LoginLayout>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={applicationBusy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
