import { useState } from "react";
import { Stack, Typography, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  getOtp,
  getUserProfile,
  resendLoginOtp,
  verifyAccess,
  verifyRegisteredUserAccess,
} from "../../service/omvApi";
import LoginLayout from "../../layouts/LoginLayout";
import { safe, setToken } from "../../util";
import CustomForm from "../../components/CustomForm";
import useQuery from "../../hooks/useQuery";
import { useAuthenticateNavigating } from "../../hooks/useAuthenticateNavigating";
import { DEFAULT_AUTHENTICATED_PATH } from "../../constants/pathConstants";

export default function Authenticate() {
  useAuthenticateNavigating(false);
  const navigate = useNavigate();
  const { texts } = useSelector((state) => state.language);
  const phone = useSelector((state) => state.phone);
  const [otp, setOtp] = useState("");
  const [busy, setBusy] = useState(false);
  const query = useQuery();
  const registered = query.get("registered") === "1";

  const onOtpComplete = async (value) => {
    verifyAndSignIn(value);
  };

  const submitForm = () => {
    if (otp.length < 5) {
      toast.error(texts.comon.wrong_otp);
      return;
    }
    verifyAndSignIn(otp);
  };

  const verifyAndSignIn = (accessCode) => {
    safe(async () => {
      setBusy(true);
      let response;
      if (registered) {
        response = await verifyRegisteredUserAccess({ accessCode });
      } else {
        response = await verifyAccess({ accessCode });
      }
      setToken(response.data.token);
      await getUserProfile();
      navigate(DEFAULT_AUTHENTICATED_PATH);
    }).finally(() => {
      setBusy(false);
    });
  };

  const submitResendOtp = () =>
    safe(async () => {
      if (busy) {
        return;
      }
      setBusy(true);
      if (registered) {
        await resendLoginOtp();
      } else {
        await getOtp({ phoneNo: phone });
      }
      toast.success(texts.common.re_sent_otp.replace("{phone}", phone));
    }).finally(() => {
      setBusy(false);
    });

  return (
    <LoginLayout>
      <CustomForm onSubmit={submitForm}>
        <Stack spacing={4}>
          <Typography variant="title" component="h4" align="left">
            {texts.common.ver_num}
          </Typography>
          <Typography component="h4" align="left" sx={{ mb: 6 }}>
            {texts.common.sent_otp} {phone}
            <br />
            <Link component={RouterLink} to="/login">
              {texts.common.not_u}
            </Link>
          </Typography>
          <Typography component="h4" align="left" sx={{ mb: 6 }}>
            {texts.common.enter_otp}
          </Typography>
          <MuiOtpInput
            value={otp}
            onChange={(value) => setOtp(value)}
            validateChar={(value) => /\d/.test(value)}
            length={5}
            onComplete={onOtpComplete}
            autoFocus
            TextFieldsProps={{
              disabled: busy,
            }}
          />
          <Typography component="h4" align="left" sx={{ mb: 6 }}>
            {texts.common.not_received}
            <Link
              component="button"
              sx={{ ml: 1 }}
              onClick={submitResendOtp}
              type="button"
              disabled={busy}
            >
              {texts.common.resend}
            </Link>
          </Typography>
          <LoadingButton
            variant="submit"
            sx={{ mt: 2 }}
            type="submit"
            loading={busy}
          >
            {texts.common.verify_signin}
          </LoadingButton>
        </Stack>
      </CustomForm>
    </LoginLayout>
  );
}
