import { createSlice } from "@reduxjs/toolkit";
import countryCodes from "country-codes-list";
import { PHONE_ACCEPTABLE_COUNTRIES } from "../constants/sharedConstants";

const countryPhoneCodes = countryCodes
  .all()
  .filter((country) => PHONE_ACCEPTABLE_COUNTRIES.includes(country.countryCode))
  .map((country) => {
    return {
      label: country.countryCode,
      value: country.countryCode,
      name: country.countryNameEn,
      phonePrefix:
        country.countryCode === "RO"
          ? "+407"
          : `+${country.countryCallingCode}`,
    };
  });

export const countryPhoneCodeSlice = createSlice({
  name: "countryPhoneCode",
  initialState: {
    countryPhoneCodes,
  },
  reducers: {},
});

export const selectCountryPhoneCodes = (state) =>
  state.countryPhoneCode.countryPhoneCodes;

export const countryPhoneCodeReducer = countryPhoneCodeSlice.reducer;
