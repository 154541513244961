import { Box, Grid, List, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const styles = {
  boxSection: {
    maxWidth: "300px",
  },
  blurbText: {
    color: "#010309",
    fontSize: "16px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: "700",
  },
  blurbIcon: {
    height: "43px",
  },
  blurb: {
    width: "150px",
  },
};

export default function CarHomeAd() {
  const { texts } = useSelector((state) => state.language);
  return (
    <Box
      className='carhome-ad'
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right center",
        backgroundColor: "white",
        backgroundImage: "url(/carhome-banner.png)",
        maxHeight: "710px",
        minHeight: "710px",
        minWidth: "650px",
      }}
    >
      <Box
        sx={{
          paddingTop: "40px",
          paddingLeft: "40px",
          paddingRight: "3px",
          marginBottom: "16px",
        }}
      >
        <Box sx={styles.boxSection}>
          <Typography
            sx={{
              color: "#0D173A",
              fontFamily: '"Manrope", sans-serif',
              fontSize: "25px",
              fontWeight: "400",
            }}
          >
            {texts.notification.we_present}
          </Typography>
          <Typography
            sx={{
              color: "#5367FC",
              marginTop: "-5px",
              marginBottom: "10px",
              fontFamily: '"Manrope", sans-serif',
              fontSize: "44px",
              fontWeight: "800",
              lineHeight: "42px",
            }}
          >
            CarHome
          </Typography>
          <Typography
            sx={{
              color: "rgba(13, 23, 58, 0.8)",
              marginBottom: "19px",
              fontFamily: '"Inter", sans-serif',
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {texts.notification.one_app}
          </Typography>
        </Box>
        <Box textAlign='center' sx={styles.boxSection}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                style={{
                  borderBottom: "1px solid #E2E2E2",
                  paddingBottom: "13px",
                  marginBottom: "13px",
                }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{ ...styles.blurb, borderRight: "1px solid #E2E2E2" }}
                  >
                    <img
                      src='/insurance-icon.svg'
                      alt='Insurance'
                      style={styles.blurbIcon}
                    />
                    <Typography sx={styles.blurbText}>
                      {texts.notification.insurance}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={styles.blurb}>
                    <img
                      src='/bridge-tax-icon.svg'
                      alt='Bridge Tax'
                      style={styles.blurbIcon}
                    />
                    <Typography sx={styles.blurbText}>
                      {texts.notification.brdige_tax}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ ...styles.blurb, borderRight: "1px solid #E2E2E2" }}>
                <img
                  src='/roviniete-icon.svg'
                  alt='Roviniete'
                  style={styles.blurbIcon}
                />
                <Typography sx={styles.blurbText}>
                  {texts.notification.rovin}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={styles.blurb}>
                <img
                  src='/document-wallet-icon.svg'
                  alt='Document Wallet'
                  style={styles.blurbIcon}
                />
                <Typography sx={styles.blurbText}>
                  {texts.notification.wallet}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.boxSection}>
          <Typography
            sx={{
              color: "#0D173A",
              marginBottom: "9px",
              marginTop: "20px",
              opacity: "0.6",
              fontSize: "15px",
              fontFamily: '"Inter", sans-serif',
              fontWeight: "400",
            }}
          >
            {texts.notification.available}
          </Typography>
          <List sx={{marginBottom: "20px"}}>
              <a target='_blank' rel='noreferrer'
                 href='https://play.google.com/store/apps/details?id=com.omvpetrom.carhome'>
                  <img
                      src='/google-play-icon.svg'
                      alt='Google Play'
                      style={{marginRight: "15px"}}
                  />
            </a>
              <a target='_blank' rel='noreferrer' href='https://apps.apple.com/tr/app/carhome-romania/id6443695033'>
                  <img src='/app-store-icon.svg' alt='App Store'/>
              </a>
          </List>
          <Typography
            sx={{
              color: "#0D173A",
              marginBottom: "9px",
              fontSize: "16px",
              fontFamily: '"Inter", sans-serif',
              fontWeight: "700",
            }}
          >
            {texts.notification.download}
          </Typography>
          <Box className='center'>
            <img src='qr-code-icon1.svg' alt='QR Code' />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
