export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const REACT_APP_SENTRY_ENABLED =
  process.env.REACT_APP_SENTRY_ENABLED === "true";
export const REACT_APP_SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;

export const PHONE_ACCEPTABLE_COUNTRIES =
  process.env.REACT_APP_PHONE_ACCEPTABLE_COUNTRIES?.split(",") || ["RO"];
export const PHONE_DEFAULT_COUNTRY =
  process.env.REACT_APP_PHONE_DEFAULT_COUNTRY || "RO";

export const ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR";
export const ROLE_SMS_VERIFYING_USER = "ROLE_SMS_VERIFYING_USER";
export const ROLE_UNREGISTERED_USER = "ROLE_UNREGISTERED_USER";

export const SUPPORTED_LANGUAGES =
  process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") || ["RO", "EN", "BG"];
export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE || "EN";
