import { getGuid, getToken, isValidToken, isVerifyingToken } from "../util";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEFAULT_AUTHENTICATED_PATH,
  DEFAULT_UNAUTHENTICATED_PATH,
} from "../constants/pathConstants";
import useQuery from "./useQuery";
import { useAuthentication } from "./useAuthentication";
import { selectOrderGuid } from "../state/guidSlice";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../state/userInfoSlice";

export const useAuthenticateNavigating = (isAuthenticatedPage = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getToken();
  const query = useQuery();
  const { handleLogout } = useAuthentication();
  const registered = query.get("registered");
  const orderGuid = useSelector(selectOrderGuid);
  const userInfo = useSelector(selectUserInfo);

  const handleUnauthenticated = useCallback(() => {
    handleLogout();
    navigate(DEFAULT_UNAUTHENTICATED_PATH);
  }, [token]);

  const handleAuthenticated = useCallback(() => {
    navigate(DEFAULT_AUTHENTICATED_PATH);
  }, [token]);

  const handleAuthNavigating = useCallback(
    (handleValidToken = true, handleInvalidToken = true) => {
      if (isValidToken(token)) {
        if (handleValidToken) {
          handleAuthenticated();
        }
      } else {
        if (handleInvalidToken) {
          handleUnauthenticated();
        }
      }
    },
    [token, handleUnauthenticated, handleAuthenticated],
  );

  useEffect(() => {
    if (isAuthenticatedPage) {
      // Authenticate Page
      if (!isValidToken(token)) {
        handleUnauthenticated();
        return;
      }
      if (location.pathname === "/") {
        // Default authenticated route
        handleAuthenticated();
      }
    } else {
      // Unauthenticated Page
      if (location.pathname === "/authenticate" && registered === "1") {
        // Input OTP page
        if (!isVerifyingToken(token)) {
          console.log(
            "Navigate to default UNAUTHENTICATED page due to Token is not verifying",
          );
          handleAuthNavigating(false, true);
        }
        return;
      }
      if (
        location.pathname === "/login" &&
        (orderGuid?.length || !userInfo?.registered)
      ) {
        console.log(
          "In process to access order with phone number. Not handle auth navigating",
        );
        return;
      }
      if (
        location.pathname === "/login-as-registered" &&
        (orderGuid?.length || !userInfo?.registered)
      ) {
        console.log(
          "In process to login while activating account. Not handle auth navigating",
        );
        return;
      }
      handleAuthNavigating(true, false);
    }
  }, [
    token,
    handleAuthNavigating,
    handleUnauthenticated,
    handleAuthenticated,
    orderGuid,
    userInfo,
  ]);
};
