import React from "react";
import { Modal, Fade, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const CustomModal = ({ isOpen, handleClose, children, ...otherProps }) => {
  return (
    <Modal open={isOpen} onClose={handleClose} closeAfterTransition {...otherProps}>
      <Fade in={isOpen}>
        <Box
          sx={{
            position: "absolute",
            top: { xs: "5%", sm: "50%" },
            left: { xs: "5%", sm: "50%" },
            transform: { sm: "translate(-50%, -50%)" },
            maxHeight: "100vh",
            overflowY: "auto",
            width: { xs: "90%", sm: "650px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 8,
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "fixed",
              right: { xs: "calc(5% + 8px)", sm: 8 },
              top: { xs: "calc(5% + 8px)", sm: 8 },
              zIndex: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomModal;
