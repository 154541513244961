import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import drawerReducer from "./drawerSlice";
import guidReducer from "./guidSlice";
import phoneReducer from "./phoneSlice";
import userInfoReducer from "./userInfoSlice";
import customerDataReducer from "./customerDataSlice";
import { applicationControlReducer } from "./applicationControlSlice";
import { eVoucherReducer } from "./evoucherSlice";
import { countryPhoneCodeReducer } from "./countryCodeSlice";

export const store = configureStore({
  reducer: {
    language: languageReducer,
    drawer: drawerReducer,
    guid: guidReducer,
    phone: phoneReducer,
    userInfo: userInfoReducer,
    customerData: customerDataReducer,
    applicationControl: applicationControlReducer,
    eVoucher: eVoucherReducer,
    countryPhoneCode: countryPhoneCodeReducer,
  },
});
