import React, { memo } from "react";
import { DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { DownloadEVouchersButton } from "../DownloadEVouchersButton";

export const EVoucherModalHeader = memo(function EVoucherModalHeader(props) {
  const { handleCloseEVoucherModal, orderItem } = props;
  const { texts } = useSelector((state) => state.language);

  return (
    <DialogTitle id="evoucher-modal-title">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <span>{texts.order.viewDetails}</span>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Grid item>
              <DownloadEVouchersButton
                guid={orderItem.guid}
                label={texts.order.eVoucher}
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleCloseEVoucherModal}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
  );
});
