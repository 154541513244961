import API from "./omvApiConfig";
import { store } from "../state/store";
import { setUserInfo } from "../state/userInfoSlice";
import { setCustomerData } from "../state/customerDataSlice";
import { getGuid, removeToken, setToken, urlByRole } from "../util";

export const getOtp = async (otpInput) => {
  removeToken();
  const response = await API.post(
    "/orders/" + getGuid() + "/request-access",
    otpInput,
    {
      params: otpInput,
    },
  );
  return response?.data ? response?.data : response;
};

export const resendLoginOtp = async () => {
  const response = await API.post("/auth/login/resend-otp");
  return response?.data ? response?.data : response;
};

export const getUserProfile = async () => {
  const response = await API.get("/profile/info");
  const data = response?.data ? response?.data?.data.userInfo : response;
  store.dispatch(setUserInfo(data));
};

export const requestActivateAccount = async () => {
  const response = await API.post("/activate-account/request", {});
  return response?.data ? response?.data : response;
};

export const verifyActivateAccount = async (verifyInput) => {
  const response = await API.post("/activate-account/verify-sms", verifyInput);
  return response?.data ? response?.data : response;
};

export const forgotPassword = async (email) => {
  const response = await API.post("/auth/forgot-password", { email });
  return response?.data ? response?.data : response;
};

export const verifyAccess = async (verifyInput) => {
  const response = await API.post(
    "/orders/" + getGuid() + "/verify-access",
    verifyInput,
    {
      params: verifyInput,
    },
  );

  const accessToken = response?.data?.data?.accessToken;
  if (accessToken) {
    setToken(accessToken);
  } else {
    removeToken();
  }
  return response?.data;
};

export const verifyRegisteredUserAccess = async (verifyInput) => {
  const response = await API.post("/auth/login/verify-sms", verifyInput, {
    withCredentials: true,
  });
  const accessToken = response?.data?.data?.accessToken;
  if (accessToken) {
    setToken(accessToken);
  } else {
    removeToken();
  }
  return response?.data;
};
export const registerPassword = async (input) => {
  const response = await API.post("/activate-account", input);
  return response?.data;
};

export const getEvList = async (params) => {
  const response = await API.get(urlByRole("/vouchers"), {
    params,
  });
  return response?.data?.data;
};

export const getOrders = async (params) => {
  const response = await API.get(urlByRole("/orders"), {
    params,
  });
  return response?.data?.data;
};

export const getVouchers = async () => {
  const response = await API.get("/vouchers");
  return response?.data?.data;
};

export const viewVoucher = async (serialNo) => {
  const response = await API.get(urlByRole(`/vouchers/${serialNo}/view`), {
    responseType: "blob",
  });

  return response?.data;
};

export const downloadVoucher = async (payload) => {
  let url = `/vouchers/download`;
  const response = await API.post(
    urlByRole(url),
    {
      orderGuid: payload?.guid,
      serialNos: payload?.serialNos,
      format: payload?.format,
    },
    {
      responseType: "blob",
    },
  );
  return response?.data;
};

export const downloadInvoice = async (payload) => {
  const response = await API.get(
    urlByRole(`/invoices/${payload?.invoiceNumber}/download`),
    {
      responseType: "blob",
    },
  );
  return response?.data;
};

export const login = async (loginInput) => {
  const response = await API.post("/auth/login", loginInput, {
    withCredentials: true,
  });

  return response?.data;
};

export const getCustomers = async (loginInput) => {
  await API.post("/authaccount/login", null, {
    params: loginInput,
  });
  const data = "valid"; // ??

  return { token: data };
};

export const getCustomerInfo = async () => {
  const response = await API.get("/customers/self");
  const data = response?.data?.data;
  store.dispatch(setCustomerData(data));
};

export const getInvoices = async () => {
  const response = await API.post("/authaccount/login");
  return response?.data?.data;
};

export const getReports = async () => {
  const response = await API.post("/authaccount/login");
  return response?.data?.data;
};

export const getTermsConditions = async (tenCode) => {
  const customId = tenCode + "-TERMS-APP";
  const response = await API.get("/public/terms/" + customId);
  return response?.data?.data;
};

export const resetPassword = async (accessCode, newPassword) => {
  const params = {
    accessCode,
    password: newPassword,
  };

  const response = await API.post("/auth/reset-password", params);
  return response?.data?.data;
};
