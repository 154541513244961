import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ChooseLanguage from "../components/ChooseLanguage";
import CarHomeAd from "../components/CarHomeAd";
import useQuery from "../hooks/useQuery";
import { getTermsConditions } from "../service/omvApi";
import { safe, setGuid, validateGuid } from "../util";
import { setStateGuid } from "../state/guidSlice";

const bgColorStyle = `
    body, html {
        background-color: #EFFAE8;
    }
`;

export default function OrderAuthenticatedAcceptTerms() {
  const { texts, language } = useSelector((state) => state.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [terms, setTerms] = useState("");
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [agree, setAgree] = useState(false);
  const query = useQuery();
  const orderGuid = query.get("orderGuid");

  useEffect(() => {
    safe(async () => {
      const response = await getTermsConditions(
        process.env.REACT_APP_TENANT_ID,
      );
      const { title, documentHtml } = response;
      setTerms({ title, documentHtml });
    });
  }, [language]);

  const onTrackInvoice = () => {
    if (!agree) {
      return;
    }
    if (!validateGuid(orderGuid)) {
      return;
    }
    dispatch(setStateGuid(orderGuid));
    setGuid(orderGuid);
    navigate("/login");
  };

  return (
    <Box>
      <style>{bgColorStyle}</style>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#EFFAE8", color: "black", paddingY: "10px" }}
        elevation={0}
      >
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={6}
              container
              alignItems="center"
              sx={{ paddingBottom: isMobile ? "20px" : "0px" }}
            >
              <img
                src="/logo-blue.svg"
                alt="ovv logo"
                style={{
                  width: 74,
                  height: 53,
                }}
              />
              <Divider orientation="vertical" sx={{ height: 30, mx: 3 }} />
              <Typography variant="body" sx={{ flexGrow: 1 }}>
                {texts.notification.access_voucher}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Box
                sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
              >
                <Typography variant="body" sx={{ mr: 1 }}>
                  {texts.common.already_account}
                  <Link
                    component={RouterLink}
                    to="/login-as-registered"
                    sx={{ ml: 1, textDecoration: "none" }}
                  >
                    {texts.activate_account.signin}
                  </Link>
                </Typography>
                <ChooseLanguage />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Box className="center" sx={{ alignItems: "stretch", paddingY: "16px" }}>
        {!isTablet && process.env.REACT_APP_TENANT_ID === "RO" && (
          <>
            <CarHomeAd />
            <Divider orientation="vertical" sx={{ height: "700px", mx: 3 }} />
          </>
        )}
        <Box>
          <Box
            sx={{
              maxHeight: isMobile ? "400px" : "630px",
              minHeight: isMobile ? "400px" : "630px",
              maxWidth: "650px",
              backgroundColor: "white",
              padding: "16px",
              overflowY: "scroll",
            }}
          >
            <Typography
              variant="title"
              component="h2"
              sx={{ marginBottom: "24px" }}
            >
              {terms.title}
            </Typography>
            <div
              style={{ padding: "16px" }}
              dangerouslySetInnerHTML={{ __html: terms.documentHtml }}
            ></div>
          </Box>
          {Boolean(orderGuid) && (
            <Stack className="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    variant="gray"
                    onChange={(e) => setAgree(e.target.checked)}
                  />
                }
                label={texts.notification.i_agree}
              />
              <Button variant="gray" disabled={!agree} onClick={onTrackInvoice}>
                {texts.notification.track_invoice}
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
}
