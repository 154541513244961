import PropTypes from "prop-types";

const CustomForm = ({ children, onSubmit, ...otherProps }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} {...otherProps}>
      {children}
    </form>
  );
};

CustomForm.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
};

export default CustomForm;
