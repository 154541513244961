import React, { memo } from "react";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const ViewVoucherButton = memo(function ViewVoucherButton(props) {
  const { params, onViewVoucher, label } = props;

  return (
    <Button
      variant="action"
      startIcon={<VisibilityIcon />}
      onClick={(event) => {
        event.stopPropagation();
        onViewVoucher(params.row);
      }}
    >
      {label}
    </Button>
  );
});
