import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: window.innerWidth > 600,
  reducers: {
    openDrawer: () => {
      return true;
    },
    closeDrawer: () => {
      return false;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
